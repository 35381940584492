.showcase {
    .showcase-text {
      padding: 3rem;
    }
    .showcase-img {
      min-height: 30rem;
      background-size: cover;
    }
    @media (min-width: 768px) {
      .showcase-text {
        padding: 7rem;
      }
    }
  }


$red: #AA151B;
$yellow: #F1BF00;
$blue: #0039f0;
$pink: #ed72aa;
$green:  #99D98C;
$orange: #fd7e14;
$primary: $green;
$enable-gradients: true;
$warning: $orange;

@import "variables.scss";
@import "node_modules/bootstrap/scss/bootstrap";
@import "mixins.scss";
@import "masthead.scss";
@import "showcase.scss";

@font-face {
  font-family: LEIXO;
  src: url(../../public/LEIXO-DEMO.woff);
}

.jumbotron {
  position: relative;
  background-image: url("../../public/images/edition-2022/portada.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: white;
  //   background-size: cover;
}

.jumbotron>.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #598850;
  opacity: 60%;
  z-index: 1;
}

.jumbotron>.container {
  position: relative;
  z-index: 2;
}

.section-title {
  letter-spacing: 3px;
}

.carousel-inner {
  max-height: 40rem;
}

.project-table {
  font-size: 15px;
}


.sponsor-logo {
  max-width: 80%;
  margin: 2em;
}

.organizer-logo {
  max-width: 80%;
  margin: 2em;
}

.alert {
  border-radius: 0;
}

.table {
  border-radius: 0;
}